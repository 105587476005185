<script setup>
  import zindagiPopup from '@/views/activity/zindagi/compontent/zindagi-popup.vue'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import { strTitleCase } from '@/util/func'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const userCenterRef = ref()
  const zindagiRef = ref()

  const data = reactive({
    rewardslist: [
      //   { id: '1', updated_at: '2024-04-26T07:04:43.000000Z', status: 'Pending' },
      //   { id: '2', updated_at: '2024-04-26T07:04:43.000000Z', status: 'Approved' },
      //   { id: '3', updated_at: '2024-04-26T07:04:43.000000Z', status: 'Rejected' }
    ],
    isInitialized: false, // 初始化是否完成
    loading: false,
    curItem: {},
    isRewardsVisible: false // rewardslist列表弹窗
  })

  const showRewards = () => {
    if (!userStore.userInfo.userId) return
     getCashbackList()
    data.isRewardsVisible = true
  }

  const hideRewards = () => {
    data.isRewardsVisible = false
  }

  const goLogin = () => {
    if (!userStore.userInfo.userId) return
    proxy.$router.replace({
      path: '/login'
    })
  }

  const getStatusClass = (status) => {
    switch (status) {
      case 'pending':
        return 'pending-status'
      case 'approved':
        return 'approved-status'
      case 'rejected':
        return 'rejected-status3'
      default:
        return 'pending-status'
    }
  }

  // 增加返现=>弹出返现弹窗
  const addRewards = () => {
    data.type = 'add'
    data.curItem = {}
    // 弹出弹出
    if (data.rewardslist.length > 0) {
      data.curItem = {
        name: data.rewardslist[0].name,
        phoneNo: data.rewardslist[0].phone_no,
        idCardNo: data.rewardslist[0].id_card_no,
        idCardIssuanceDate: data.rewardslist[0].id_card_issuance_date
      }
    }
    console.log('弹出返现弹窗')
    nextTick()
    // 弹出弹出
    zindagiRef.value.zindagiPopupShow({
      type: 'add',
      curItem: data.curItem
    })
    hideRewards()
  }

  // 跳转返现页面
  const goRewardsAll = () => {
    goLogin()
    userStore.setUserShowPage({ page: 'cashback', selectType: 'cashbackList' })
    proxy.$router.replace({
      path: '/USERINFO'
    })
    console.log('跳转返现页面')
  }

  // 获取返现列表
  const getCashbackList = () => {
    if (JLTools.isNull(userStore.userInfo.userId)) return
    data.loading = true
    let parms = {
      user_id: userStore.userInfo.userId,
      per_page: 3,
      page: 1,
      sorting: 'DESC'
    }
    api.activity
      .getCashbackList(parms)
      .then((res) => {
        // console.log(res, 'getCashbackList')
        data.loading = false
        data.rewardslist = res.data.data.slice(0, 3)
        data.isInitialized = true
      })
      .catch(() => {
        data.isInitialized = true
        data.loading = false
      })
  }

  onMounted(() => {
    getCashbackList()
  })
</script>

<template>
  <div class="user-content">
    <div class="user-box" :class="userStore.userInfo.userId ? 'logged-box' : ''" @mouseleave.stop="hideRewards">
      <div class="title" @click="goRewardsAll" @mouseover.stop="showRewards">Cashback & Rewards</div>

      <div class="rewards-list-box" :style="{ display: data.isRewardsVisible ? 'block' : 'none' }">
        <div class="rowBC">
          <div class="sub-title">Recent</div>
          <div v-if="data.isInitialized && !data.loading" class="add-rewards rowCC" @click="addRewards">
            <div class="add-ico"></div>
            <div>Claim Cashback</div>
          </div>
        </div>
        <el-divider class="divider-box" />
        <div v-loading="data.loading">
          <template v-if="data.rewardslist.length && data.isInitialized">
            <div class="rewards-list">
              <div v-for="(item, index) in data.rewardslist" :key="index" class="rewards-item rowSC" @click="handleFacility(item)">
                <div class="rowSS kv-box">
                  <JLImg class="ico" src="b5be74f1.png"></JLImg>
                  <div>
                    <div>Request No</div>
                    <el-divider class="divider-kv" />
                    <div>{{ item.id }}</div>
                  </div>
                </div>
                <div class="rowSS kv-box data-box">
                  <JLImg class="ico" src="e50afc25.png"></JLImg>
                  <div>
                    <div>Request Date</div>
                    <el-divider class="divider-kv" />
                    <div>{{ JLTools.formatDate(item.updated_at) }}</div>
                  </div>
                </div>
                <div class="rowSS kv-box">
                  <JLImg class="ico" src="a0986603.png"></JLImg>
                  <div>
                    <div>Status</div>
                    <el-divider class="divider-kv" />
                    <div class="rowSC">
                      <div class="status" :class="getStatusClass(item.log_status)"></div>
                      <div>{{ strTitleCase(item.log_status) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <el-empty description="No record" />
          </template>
        </div>

        <div class="rewards-all-btn" @click="goRewardsAll">View All</div>
      </div>

      <zindagiPopup ref="zindagiRef"></zindagiPopup>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .user-content {
    position: relative;
    .title {
      cursor: pointer;
      line-height: 24px;
      padding: 0 20px;
      border-right: 1px solid rgba(0, 0, 0, 0.25);
    }
    /* .logged-box {
      &:hover {
        .rewards-list-box {
          display: block;
        }
      }
    } */
  }

  .rewards-list-box {
    display: none;
    position: absolute;
    top: 100%;
    left: 18px;
    min-width: 400px;
    min-height: 100px;
    padding: 10px;
    background: #fff;
    border-top: 0px;
    border-radius: 6px;
    z-index: 2009;
    box-shadow: 0px 6px 10px 10px rgba(136, 191, 255, 0.12);
    .sub-title {
      font-size: 16px;
    }
    .add-rewards {
      padding: 4px 8px;
      color: #fff;
      background: var(--btnBgColor);
      border-radius: 20px;
      cursor: pointer;
      .add-ico {
        background: url('https://cdn.qingmeidata.com/3411f440.png') no-repeat;
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }
    .divider-box {
      margin: 8px 0;
    }
    .rewards-item {
      cursor: pointer;
      padding: 10px 0;
      border-bottom: 1px solid #dcdfe6;
      &:last-child {
        border-bottom: none;
      }
      .ico {
        width: 14px;
        height: 14px;
        margin-top: 3px;
        margin-right: 5px;
      }
      .kv-box {
        margin: 0 15px;
        .divider-kv {
          width: 80px;
          margin: 4px 0;
        }
        .status {
          width: 6px;
          height: 6px;
          margin-right: 3px;
          border-radius: 50%;
        }
        .pending-status {
          background: rgba(255, 165, 7, 1);
        }
        .approved-status {
          background: rgba(33, 181, 0, 1);
        }
        .rejected-status3 {
          background: rgba(246, 63, 66, 1);
        }
        &:first-child {
          margin: 0 15px 0 0;
        }
        &:last-child {
          margin: 0 0 0 15px;
        }
      }
      .data-box {
        flex: 0 0 120px;
      }
    }
    .rewards-all-btn {
      width: fit-content;
      padding: 8px 16px;
      margin: 0 auto;
      color: var(--btnBgColor);
      border: 2px solid rgba(0, 0, 0, 1);
      border-radius: 24px;
      cursor: pointer;
    }
  }

  .user-item {
    padding: 0 0px 0 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    .ico {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      background-size: 100% 100%;
    }
    .custom-radio {
      position: relative;
      display: inline-block;
      width: 26px; /* 调整大小 */
      height: 26px; /* 调整大小 */
      border: 2px solid #fff; /* 设置边框 */
      border-radius: 50%; /* 使其变成圆形 */
      margin-right: 10px;
      .ico {
        width: 16px;
        height: 16px;
        margin-right: 0px;
        background-size: 100% 100%;
      }
    }
    .user-name {
      max-width: 100px;
      font-weight: 300;
    }
  }
</style>
