<script setup>
  import { ElMenu } from 'element-plus'
  import { getCurrentInstance, onMounted, ref, reactive, computed, nextTick, toRaw, provide } from 'vue'
  import { ArrowLeft, ArrowRight } from '@element-plus/icons'
  import subMenu from './SubMenu.vue'
  import api from '@/api'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()

  const { proxy } = getCurrentInstance()

  const data = reactive({
    menuData: [],
    keyPathList: [] // 当前选中的目录
  })
  onMounted(() => {
    getMenuData()
  })
  // 目录滚动功能
  const scrollWrap = ref()
  const scrollBox = ref()
  let scrollBoxLeft = ref(0)
  const scrollBoxStyle = computed(() => {
    return {
      left: scrollBoxLeft.value + 'px'
    }
  })
  const eachMoveDistance = 100
  const moveLeft = () => {
    let maxScrollDistance = scrollBox.value.offsetWidth - scrollWrap.value.offsetWidth
    if (scrollBoxLeft.value > -maxScrollDistance) {
      if (scrollBoxLeft.value - eachMoveDistance < -maxScrollDistance) {
        scrollBoxLeft.value = -maxScrollDistance
      } else {
        scrollBoxLeft.value -= eachMoveDistance
      }
    }
  }
  const moveRight = () => {
    if (scrollBoxLeft.value < 0) {
      if (scrollBoxLeft.value + eachMoveDistance > 0) {
        scrollBoxLeft.value = 0
      } else {
        scrollBoxLeft.value += eachMoveDistance
      }
    }
  }
  // 获取家用电器目录
  const getMenuData = async () => {
    if (!HsystemStore.menuData.length) {
      HsystemStore.getMenuDataList()
    }
  }

  // 记录当前目录
  const handleOpen = (key, keyPath) => {
    data.keyPathList = toRaw(keyPath)
  }

  // 获取目录信息
  const findHierarchyId = (menu) => {
    const productData = []
    // 查询当前目录的层级辅助函数
    const findItemById = (items, id) => items.find((item) => item.id === Number(id))

    for (let i = 0; i < menu.level; i++) {
      const id = i === menu.level - 1 ? menu.id : data.keyPathList[i]
      if (!id) break

      const currentItem = i === 0 ? findItemById(HsystemStore.menuData, id) : findItemById(productData[i - 1]?.children || [], id)

      if (currentItem) {
        productData.push(toRaw(currentItem))
      } else {
        console.error(`Item with ID ${id} not found at level ${i + 1}`)
        break
      }
    }

    // console.log(productData, 'productData')
    setBreadcrumbList(productData)
  }

  // 页内导航
  const setBreadcrumbList = (productData) => {
    // console.log(productData, 'productData')
    const levels = ['firstCategoryId', 'secondCategoryId', 'thirdCategoryId']
    const breadcrumbList = productData.map((item, index) => ({
      name: item?.name || '',
      data: {
        name: 'blankPage',
        params: {
          pageName: 'productList',
          [levels[index]]: item?.id || ''
        }
      }
    }))

    // console.log(breadcrumbList, 'breadcrumbList')

    HsystemStore.setBreadcrumbList(breadcrumbList)
  }

  provide('findHierarchyId', findHierarchyId)
</script>

<template>
  <div class="scrollBar" :class="HsystemStore.menuData.length < 7 ? 'minScrollBar' : ''">
    <arrow-left class="arrowLeft arrow" @click="moveRight" />
    <div class="categories" ref="scrollWrap" id="scrollWrap">
      <div class="scrollBox" ref="scrollBox" :style="scrollBoxStyle">
        <ElMenu class="el-menu" active-text-color="#0C5CA8" mode="horizontal" @open="handleOpen">
          <sub-menu :menuData="HsystemStore.menuData" />
        </ElMenu>
      </div>
    </div>
    <arrow-right class="arrowRight arrow" @click="moveLeft" />
  </div>
</template>

<style scoped lang="less">
  .scrollBar {
    display: flex;
    height: 100%;
    width: var(--minWidth);
    margin: 0 auto;
    align-items: center;
    user-select: none;
    .categories {
      width: 100%;
      height: 100%;
      flex: 1;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      .scrollBox {
        /* transition: left 0.2s; */
        position: absolute;
        top: 0;
        height: 100%;
      }
    }
    .arrow {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: #000;
      /* padding: 0 8px; */
      &:hover {
        color: var(--btnBgColor);
      }
    }
    .arrowLeft {
      margin-right: 18px;
    }
    .arrowRight {
      margin-left: 18px;
    }
  }
  .minScrollBar {
    .categories {
      .scrollBox {
        position: absolute;
        top: 50%;
        left: 50% !important;
        transform: translate(-50%, -50%);
      }
    }
    .arrow {
      display: none;
    }
  }

  .el-menu {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }
  .el-menu::-webkit-scrollbar {
    display: none;
  }

  /* 去掉el-menu箭头 */
  .el-menu:deep(.el-sub-menu) .el-sub-menu__icon-arrow {
    display: none;
  }
  /* 调整目录字体样式 */
  .el-menu:deep(.el-sub-menu) .el-sub-menu__title,
  .el-menu:deep(.el-menu-item) {
    font-weight: 500;
    font-size: 16px;
    padding-left: 0;
  }
  /* 调整 is-active 样式 */
  .el-menu:deep(.el-menu-item.is-active) {
    color: #000;
  }
  .el-menu:deep(.el-sub-menu.is-active) .el-sub-menu__title {
    border-bottom: none !important;
    color: #000 !important;
  }
  .el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu--horizontal:deep(.el-sub-menu.is-active) .el-sub-menu__title {
    border-bottom: none !important;
    color: #000 !important;
  }
</style>
