<!-- 首页header -->
<template>
  <div class="haier-header-box">
    <div class="rowBC control-box page-box" data-remark="header-top">
      <div class="rowSC">
        <div class="rowSC top-menu">
          <div v-for="(item, index) in headerTopList.slice(0, 2)" :key="index" class="top-item rowSC" @click="handleHeaderFunc(item)">
            <div class="ico" :style="{ backgroundImage: `url(${item.url})` }"></div>
            <div>{{ item.typeName }}</div>
          </div>
        </div>
        <div class="rowSC activity-box">
          <div v-for="(item, index) in data.activityList" :key="index" class="top-item rowSC" @click="toJumpExternalLinks(item.link)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>

      <div class="rowSC top-menu">
        <cashback-rewards data-remark="返现入口" v-if="getEnv() === 'development'"></cashback-rewards>
        <div v-for="(item, index) in headerTopList.slice(2, 4)" :key="index" class="top-item rowSC" @click="handleHeaderFunc(item)">
          <div class="ico" :style="{ backgroundImage: `url(${item.url})` }"></div>
          <div>{{ item.typeName }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="sticky-box-content">
    <div class="sticky-box">
      <div class="rowBC page-box header-wrap">
        <div class="rowSC wh100">
          <div class="logo rowCC" v-push="`/`">
            <img :src="headerIco.Homepage.url" width="154" />
          </div>
          <div class="search" data-remark="智能搜索">
            <auto-search></auto-search>
          </div>
        </div>
        <div class="rowSC user-menu">
          <div v-for="(item, index) in Object.values(headerIco).slice(1, 3)" :key="index" class="user-item rowSC" @click="handleHeaderFunc(item)">
            <div class="ico" :class="item.type" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="badge-box" v-if="item.type === 'Cart'">
                <div class="badge rowCC">{{ userStore.cartCount }}</div>
              </div>
            </div>
            <div>
              <div>{{ item.typeName }}</div>
            </div>
          </div>
          <!-- COUPONS -->
          <user-center></user-center>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="nav-bar">
        <category-menu />
      </div>
    </div>
  </div>
</template>

<script setup>
  import Page from '@/components/Page'
  import cashbackRewards from './cashback-rewards'
  import autoSearch from './auto-search'
  import categoryMenu from './categoryMenu'
  import userCenter from './user-center'
  import { ElMessage } from 'element-plus'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import { getEnv } from '@/util/func'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const headerTopList = [
    { type: 'tel', typeName: '(042) 111 142 437', url: 'https://cdn.qingmeidata.com/f4c71767.png' },
    { type: 'Support', typeName: 'Service & Support', url: 'https://cdn.qingmeidata.com/3d5fada9.png', link: 'https://www.haier.com/pk/service-support/', path: '' },
    { type: 'HelpCenter', typeName: 'Help Center', url: 'https://cdn.qingmeidata.com/13fdf73f.png', link: '', path: '/USERINFO' },
    { type: 'MyOrder', typeName: 'My Orders', url: 'https://cdn.qingmeidata.com/5d07a1e9.png', link: '', path: '/USERINFO' }
  ]

  const headerIco = {
    Homepage: {
      type: 'Homepage',
      typeName: 'Homepage',
      url: 'https://cdn.qingmeidata.com/f1ae18fb.png',
      link: '',
      path: '/'
    },
    coupons: { type: 'coupons', typeName: 'Coupons', attribute: '', url: 'https://cdn.qingmeidata.com/06e0fcf4.png', link: '', path: '/couponIndex' },
    cart: {
      type: 'Cart',
      typeName: 'Cart',
      attribute: '',
      url: 'https://cdn.qingmeidata.com/9c8d28f6.png',
      link: '',
      path: '/cart'
    }
  }

  const data = reactive({
    theme: 'lan',
    orderList: [], // 最近2笔订单
    searchWord: '',
    activityList: [],
    cart: [] // 购物车数量
  })

  // 处理头部top信息
  const handleHeaderFunc = (item) => {
    console.log('type', item.type)
    switch (item.type) {
      case 'Support':
        toJumpExternalLinks('https://www.haier.com/pk/service-support/')
        break
      case 'HelpCenter':
        userStore.setUserShowPage({ page: 'userHelp', selectType: 'userHelp' })
        toSkip(item.path)
        break
      case 'MyOrder':
        if (!userStore.userInfo.userId) {
          return proxy.$router.push({
            path: '/login'
          })
        }
        userStore.setUserShowPage({ page: 'orderList', selectType: 'All Orders' })
        toSkip(item.path)
        break
      default:
        if (!userStore.userInfo.userId) {
          return proxy.$router.push({
            path: '/login'
          })
        }
        if (item.path) {
          toSkip(item.path)
        }
        break
    }
  }

  // 内部跳转
  const toSkip = (path) => {
    proxy.$router.push({
      path: path
    })
  }

  // 外部
  const toJumpExternalLinks = (path) => {
    if (!path) return
    window.open(path, '_blank')
  }

  // 搜索
  const toSearch = () => {
    if (data.searchWord.trim() == '') {
      ElMessage({
        message: 'Please enter a keyword.',
        type: 'error'
      })
      return
    }
    let num = JLTools.createCode(4)
    proxy.$router.push({
      name: 'productList',
      query: {
        keyword: data.searchWord,
        id: num
      }
    })
  }

  //查询购物车列表
  const queryCartPro = () => {
    userStore.getCartList()
  }

  // 获取外部活动
  const getCampaigns = () => {
    api.common.getCampaigns().then((res) => {
      console.log(res, 'getCampaigns')
      data.activityList = res
    })
  }

  onMounted(() => {
    getCampaigns()
    queryCartPro()
  })
</script>

<style scoped lang="less">
  .sticky-box-content {
    -webkit-backface-visibility: hidden;
    position: sticky;
    top: -1px;
    z-index: 2001;
  }
  .haier-header-box {
    width: 100%;
    font-size: 14px;
  }
  .page-box {
    width: var(--minWidth);
    margin: 0 auto;
  }
  .control-box {
    padding: 5px 0;
    .top-menu {
      .top-item {
        line-height: 24px;
        padding: 0 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        cursor: pointer;
        .ico {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          background-size: 100% 100%;
        }
        &:first-child {
          padding: 0 20px 0 0;
        }
        &:last-child {
          padding: 0 0 0 20px;
          border-right: none;
        }
      }
    }
    .activity-box {
      margin-left: 16px;
      border-left: 1px solid rgba(0, 0, 0, 0.25);
      .top-item {
        line-height: 24px;
        padding: 0 20px;
        cursor: pointer;
      }
    }
  }
  .sticky-box {
    background-color: var(--mainBgColor);
    color: var(--boxBgColor);
    .header-wrap {
      padding: 5px 0;
      .logo {
        width: 150px;
        margin-right: 24px;
        margin-left: -4px;
      }
      .search {
        flex: 1;
      }
    }
    .user-menu {
      margin-left: 100px;
      .user-item {
        line-height: 24px;
        font-size: 16px;
        padding: 0 20px;
        border-right: 1px solid rgb(255, 255, 255, 0.5);
        cursor: pointer;
        .ico {
          position: relative;
          width: 22px;
          height: 22px;
          margin-right: 10px;
          background-size: 100% 100%;
        }
        .coupons {
          width: 20px;
          height: 20px;
        }
        .cart-ico {
          margin-top: -5px;
        }
        .badge-box {
          position: absolute;
          top: -1px;
          right: -3px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          font-size: 8px;
          background: red;
          .badge {
            width: 100%;
            height: 100%;
          }
        }
        .user-name {
          max-width: 120px;
        }
        &:first-child {
          padding: 0 30px 0 0;
        }
        &:last-child {
          padding: 0 0 0 30px;
          border-right: none;
        }
      }
    }
  }

  .nav {
    width: 100%;
    height: 54px;
    /* border-top: 1px solid #eee;
      border-bottom: 1px solid #eee; */
    background: #fff;
    .nav-bar {
      width: var(--minWidth);
      height: 100%;
      margin: 0 auto;
    }
  }
</style>
